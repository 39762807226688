import { PLATFORM } from 'aurelia-pal';
import { customElement, bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { CarrierNumberEditor } from './carrier-number-editor';
import { DialogService } from 'aurelia-dialog';
import { AgentNumbers } from 'services/agent-numbers';
import { c } from 'common/common';
import moment from 'moment';
PLATFORM.moduleName('./carrier-number-editor');

@customElement('member-carrier-numbers')
export class CarrierNumbers {
    static inject = [Element, Security, Api, DialogService, AgentNumbers];
    _element;
    _security;
    _api;
    _dialogService;
    _agentNumbers;

    @bindable memberId;
    @bindable showNumbers = false;
    @bindable readOnly = false;
    id;
    isMyProfile = false;

    carriers;

    constructor(element, security, api, dialogService, agentNumbers) {
        this._element = element;
        this._security = security;
        this._api = api;
        this._dialogService = dialogService;
        this._agentNumbers = agentNumbers;
        this.id = c.Helpers.uniqueId();
        this.isAdmin = this._security.isAdmin;
    }

    bind() {
        if (!this.memberId) return;
        this._load();
    }

    memberIdChanged() {
        if (!this.memberId) return;
        this._load();
    }

    async _load() {
        try {
            this.isMyProfile = this.memberId === this._security.authenticatedMemberId;
            this.isMyDownline = this.isMyProfile ? true : !this._security.isAdmin;
            this.carriers = [];
            this.agentNumberCount = 0;
            const data = await this._api.get(`member/${this.memberId}/carrier-number`);
            let hasAtLeastOne = false;
            for (let c of data) {
                const carrier = {
                    carrierId: c.carrierId,
                    carrier: c.carrierName,
                    agentNumbers: c.agentNumbers,
                    hasBalance: c.hasBalance,
                    balance: c.balance,
                    balanceAsOfDate: c.balanceAsOfDate ? moment(c.balanceAsOfDate).format('l') : '',
                    display: this.isMyProfile || this.isMyDownline ? false : true,
                };
                this.carriers.push(carrier);
                if (!c.agentNumbers) continue;
                for (let an of carrier.agentNumbers) {
                    an.statusColor = await this._agentNumbers.statusColor(an);
                }
                let carrierHasAtLeastOneNumer = carrier.agentNumbers.length > 0;
                this.agentNumberCount += carrier.agentNumbers.length;
                carrier.display = this.isMyProfile || this.isMyDownline ? carrierHasAtLeastOneNumer : true;
                if (carrier.display) hasAtLeastOne = true;
            }
            this.hasAtLeastOneNumber = hasAtLeastOne;
        } catch (err) {
            console.log(err);
        }
    }

    showList(show) {
        this.showNumbers = show;
    }

    addCarrierNumber() {
        this._openEditor(undefined, undefined);
    }

    editCarrierNumber(carrier, agentNumber) {
        this._openEditor(carrier, agentNumber);
    }

    _openEditor(carrier, agentNumber) {
        const model = {
            memberId: this.memberId,
            carrier,
            agentNumber: agentNumber || { visible: true },
        };
	    this._dialogService.open({ viewModel: CarrierNumberEditor, model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        this._load();
	    });
    }
}
