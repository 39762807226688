import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import Chart from 'chart.js/auto';
import { c } from 'common/common';

export class ChartGoalProgress {
    static inject = [Router, EventAggregator, I18n];
    _router;
    _ea;
    _i18n;

    @bindable key;
    @bindable goal;
    @bindable progress;
    @bindable ryg;
    @bindable formatMoney = false;
    @bindable decimals = 0;
    @bindable reload;
    @bindable size = 'small';

    chartEl;
    showChart = true;

    constructor(router, ea, i18n) {
        this._router = router;
        this._ea = ea;
        this._i18n = i18n;
    }

    attached() {
        this._loadChart();
    }

    goalChanged() {
        this._loadChart();
    }

    progressChanged() {
        this._loadChart();
    }

    reloadChanged() {
        this._loadChart();
    }

    rygChanged() {
        this._loadChart();
    }

    _loadChart() {
        try {
            if (!this.chartEl || this.goal === undefined || this.goal === null || this.progress === undefined || this.progress === null || this.ryg === undefined || this.ryg === null) return;
            this.neededToMeetGoal = this.goal - this.progress;
            if (this.neededToMeetGoal < 0) this.neededToMeetGoal = 0;
            const backgroundColors = [this.rygColor(), 'transparent'];
            const borderColors = [this.rygColor(), '#eeeeee'];
            const data = {
              labels: ['', ''],
              datasets: [
                    {
                        label: '',
                        data: [this.progress, this.neededToMeetGoal],
                        backgroundColor: backgroundColors,
                        borderColor: borderColors
                    }
                ]
            };
            const plugin = {
                id: 'content-at-center',
                beforeDraw: (chart) => {
                    let width = chart.width;
                    let height = chart.height;
                    let ctx = chart.ctx;
              
                    const centerText = this.formatMoney ? `$${this.progress.formatMoney(this.decimals)}` : `${c.Helpers.formatNumber(this.progress, this.decimals)}`;
                    let heightDivisor = this.centerTextDivisor(centerText);

                    let fontSize = (height / heightDivisor).toFixed(2);
                    ctx.font = `${fontSize}em sans-serif`;
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = this.rygColor();
              
                    let textX = Math.round((width - ctx.measureText(centerText).width) / 2);
                    let textY = height / 1.9;
              
                    ctx.fillText(centerText, textX, textY);
                    ctx.save();
                }
            }
            const config = {
                type: 'doughnut',
                data: data,
                plugins: [plugin],
                options: {
                    responsive: true,
                    cutout: '90%',
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                        tooltip: { enabled: false }
                    }
                },
            };

            if (this._chart) {
                this._chart.data.datasets = data.datasets;
                this._chart.update();
                return;
            }

            this._chart = new Chart(this.chartEl, config);
        } catch (err) {
            console.log(err);
        }
    }

    rygColor() {
        let color = 'black';
        switch (this.ryg) {
            case 'r': color = 'red'; break;
            case 'y': color = 'orange'; break;
            case 'g': color = 'green'; break;
            default: break;
        }
        return color;
    }

    centerTextDivisor(centerText) {
        // size = small
        let heightDivisor = 70;
        if (centerText.length >= 9) heightDivisor = 100;
        else if (centerText.length >= 8) heightDivisor = 100;
        else if (centerText.length >= 7) heightDivisor = 100;
        else if (centerText.length >= 6) heightDivisor = 100;
        else if (centerText.length >= 5) heightDivisor = 100;
        else if (centerText.length >= 4) heightDivisor = 100;
        else if (centerText.length >= 3) heightDivisor = 95;
        else if (centerText.length >= 2) heightDivisor = 80;
        return heightDivisor;
    }
}
