import { bindable } from 'aurelia-framework';
import { AgentNumbers } from 'services/agent-numbers';

export class CarrierNumber {
    static inject = [AgentNumbers];
    _agentNumbers;

    @bindable({ changeHandler: '_load' }) carrierId;

    constructor(agentNumbers) {
        this._agentNumbers = agentNumbers;
    }

    async _load() {
        try {
            if (!this.carrierId) return;
            this.loading = true;
            const carrier = await this._agentNumbers.forCarrier(this.carrierId);
            if (!carrier) return;
            for (let an of carrier.agentNumbers) {
                an.statusColor = await this._agentNumbers.statusColor(an);
            }
            this.agentNumbers = carrier.agentNumbers;
            this.carrier = carrier.carrierName;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}