import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import Chart from 'chart.js/auto';
import moment from 'moment';

export class ChartLine {
    static inject = [Router, EventAggregator, I18n];
    _router;
    _ea;
    _i18n;

    @bindable datasets;
    @bindable dateFormat = 'l';
    @bindable decimals = 0;
    @bindable reload;
    @bindable legendPosition = 'bottom';
    @bindable curved = false;

    chartEl;
    showChart = true;

    constructor(router, ea, i18n) {
        this._router = router;
        this._ea = ea;
        this._i18n = i18n;
    }

    attached() {
        this._loadChart();
    }

    datasetsChanged() {
        this._loadChart();
    }

    reloadChanged() {
        this._loadChart();
    }

    _loadChart() {
        try {
            if (!this.chartEl || !this.datasets) return;

            const data = {
                labels: undefined,
                datasets: [],
            };
            for (let dataset of this.datasets) {
                const dataValues = [];
                const dataLabels = [];
                dataset.data.forEach(d => {
                    dataValues.push(d.value);
                    dataLabels.push(moment.utc(d.date).format(this.dateFormat));
                });
                if (!data.labels) data.labels = dataLabels; // Both datasets must have the same labels
                data.datasets.push({
                    label: this._i18n.tr(dataset.key),
                    data: dataValues,
                    backgroundColor: [dataset.color],
                    borderColor: [dataset.color],
                    lineTension: this.curved ? 0.3 : 0,
                });
            }
            const config = {
                type: 'line',
                data: data,
                options: {
                    responsive: true,
                    scales: {
                        y: { min: 0 }
                    },
                    plugins: {
                        legend: { display: true, position: this.legendPosition },
                        title: { display: false },
                    }
                },
            };

            if (this._chart) {
                this._chart.data.datasets = data.datasets;
                this._chart.update();
                return;
            }

            this._chart = new Chart(this.chartEl, config);
        } catch (err) {
            console.log(err);
        }
    }
}
