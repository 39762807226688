import { bindable } from 'aurelia-framework';
import { Api } from 'common/server';

export class ContractedWithCarriers {
    static inject = [Element, Api];
    _element;
    _api;

    @bindable memberId;
    carriers;

    constructor(element, api) {
        this._element = element;
        this._api = api;
    }

    bind() {
        this._load();
    }

    memberIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.memberId) return;
        try {
            this.carriers = [];
            const data = await this._api.get(`member/${this.memberId}/carrier-number`);
            for (let c of data) {
                if (!c.agentNumbers) continue;
                const hasAgentNumber = c.agentNumbers.find(x => x.agentNumber && x.visible);
                if (!hasAgentNumber) continue;
                this.carriers.push({
                    id: c.carrierId,
                    name: c.carrierName,
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}
