import { inject, observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { I18n } from 'common/i18n';
import { Members } from 'services/members';
import { Notifier } from 'common/ui';
import { Carriers } from 'services/carriers';
import { AgentNumbers } from 'services/agent-numbers';
import moment from 'moment';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Members, Notifier, Carriers, AgentNumbers)
export class CarrierNumberEditor {
    _agentNumbers;

    id;
    @observable agentNumber;
    lineOfBusiness;
    visible = true;
    status;
    @observable statusComment;
    comments;

    lobs = ['', 'life', 'annuity', 'hma', 'ancillary'];
    statuses;
    carriers;
    carrierId;
    focusCarrier = false;
    focusNumber = false;

    constructor(dialogController, validationController, i18n, members, notifier, carriers, agentNumbers) {
        this.dialogController = dialogController;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._members = members;
        this._notifier = notifier;
        this._carriers = carriers;
        this._agentNumbers = agentNumbers;

        this.deleteText = this._i18n.tr('delete');
    }

    activate(model) {
        this.memberId = model.memberId;
        this.carrierId = undefined;
        this.carrier = model.carrier;
        this.carrierSet = this.carrier ? true : false;
        this.focusCarrier = !this.carrierSet;
        this.focusNumber = this.carrierSet;
        this.id = model.agentNumber.id;
        this.assignedDate = model.agentNumber.assignedDate;
        this.agentNumber = model.agentNumber.agentNumber;
        this.statusCode = model.agentNumber.status;
        this.lineOfBusiness = model.agentNumber.lineOfBusiness;
        this.visible = model.agentNumber.visible;
        this.comments = model.agentNumber.comments;
        this.commentDate = model.agentNumber.commentDate;
        this.validationController.reset();
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.statuses = await this._agentNumbers.agentNumberStatuses();
            this.status = this.statuses.find(x => x.code === this.statusCode) || null;
            this.carriers = await this._carriers.all();
        } catch (err) {
            console.log(err);
        }
    }

    agentNumberChanged() {
        if (this.id) return; // do not adjust anything if this is an existing agent number
        if (this.agentNumber && !this.assignedDate) this.assignedDate = moment();
        if (!this.agentNumber && this.assignedDate) this.clearAssignedDateNow = moment().valueOf();
    }

    setComment(comment) {
        this.comments = comment;
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.saving = true;

            const carrierId = this.carrierSet ? this.carrier.carrierId : this.carrierId;
            const status = this.status ? this.status.code : null;
            await this._members.saveAgentNumber(this.memberId, this.id, carrierId, this.agentNumber, this.lineOfBusiness || null, this.visible, status, this.comments, this.assignedDate);
            this._notifier.success(this._i18n.tr('members-carriers-editor-save-success'));
            this.dialogController.ok({ deleted: false });
        } catch (err) {
            this._notifier.generalError(err);
        } finally {
            this.saving = false;
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    async deleteEntry() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        this.deleting = true;
        try {
            await this._members.deleteAgentNumber(this.memberId, this.id);
            this._notifier.success('members-carriers-editor-delete-success');
            this.dialogController.ok({ deleted: true });
        } catch (err) {
            this._notifier.generalError();
        } finally {
            this.deleting = false;
        }
    }
}
