import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { CHART_COLORS } from 'common/constants';
import Chart from 'chart.js/auto';
import { mode } from 'common/ui';

export class ChartBar {
    static inject = [Element, Router, EventAggregator, I18n];
    _element;
    _router;
    _ea;
    _i18n;

    /**
     * Data must be an array of objects with { name, value }
     */
    @bindable data;
    @bindable horizontal = false;
    @bindable isCurrency = false;
    @bindable YAxisIsCurrency = false;
    @bindable noDataKey;
    @bindable reload;
    @bindable loading = false;
    @bindable heightPerRow;
    @bindable barColor;

    _chart;
    chartEl;

    constructor(element, router, ea, i18n) {
        this._element = element;
        this._router = router;
        this._ea = ea;
        this._i18n = i18n;
    }

    attached() {
        this._loadChart();
    }

    dataChanged() {
        this._loadChart();
    }

    reloadChanged() {
        this._loadChart();
    }

    _loadChart() {
        if (!this.chartEl || !this.data) return;

        try {
            const uiMode = mode();

            if (!this.data.length) {
                this.showChart = false;
                return;
            }
            this.showChart = true;
            const labels = [];
            const labelIds = [];
            const shortLabels = [];
            const values = [];
            this.data.forEach(d => {
                labels.push(d.name);
                labelIds.push(d.id);
                if (d.shortName) shortLabels.push(d.shortName); else shortLabels.push(d.name);
                values.push(d.value);
            });
            const data = {
                labels,
                labelIds,
                shortLabels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: this.barColor || CHART_COLORS.Classic(uiMode.mode),
                    }
                ]
            };
            const config = {
                type: 'bar',
                data: data,
                options: {
                    maxBarThickness: 12,
                    borderRadius: 12,
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    return this.isCurrency
                                        ? `${context.label}: $${context.formattedValue}`
                                        : `${context.label}: ${context.formattedValue}`;
                                },
                            },
                        },
                    },
                    onHover: (evt, item) => {
                        evt.native.target.style.cursor = item[0] ? 'pointer' : 'default';
                    },
                    onClick: (evt, item) => {
                        const points = this._chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
                        if (!points || !points.length) return;
                        const firstPoint = points[0];
                        const label = this._chart.data.labels[firstPoint.index];
                        const labelId = this._chart.data.labelIds[firstPoint.index];
                        const value = this._chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                        this._element.dispatchEvent(new CustomEvent('drilldown', { bubbles: true, detail: { id: labelId, name: label, value } }));
                    },
                },
            };
            if (this.YAxisIsCurrency) {
                config.options.scales = {
                    y: {
                        ticks: {
                            callback: (value, index, ticks) => {
                                return '$' + `${value}`;
                            }
                        }
                    }
                }
            }

            if (this.horizontal) {
                config.options.indexAxis = 'y';
                config.options.maintainAspectRatio = false;
            }

            if (this.heightPerRow) {
                const height = this.heightPerRow * values.length;
                this.chartContainerEl.style.height = `${height}px`;
            }

            if (this._chart) {
                this._chart.data.labels = data.labels;
                this._chart.data.labelIds = data.labelIds;
                this._chart.data.shortLabels = data.shortLabels;
                this._chart.data.datasets = data.datasets;
                this._chart.update();
                return;
            }

            this._chart = new Chart(this.chartEl, config);
        } catch (err) {
            console.log(err);
        }
    }
}
